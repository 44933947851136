import axios from "axios";
import Swal from "sweetalert2";

//https://ctms-api.januarytc.com/api/Employee/Company/GetMasterList
//https://ctms-dev.januarytc.com/#/home
//https://ctms-dev-customer.januarytc.com/#/GuildsManagement
// ctms-api-customer.j


//  正式
const baseURL = "https://ctms-api.januarytc.com";

// 測試 
//  const baseURL = "https://ctms-api-customer.januarytc.com";

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // 從 localStorage 將 token 取出
    const token = localStorage.getItem("token");

    // 如果 token 存在的話，則帶入到 headers 當中
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export const apiHelper = axiosInstance;

export const Toast = Swal.mixin({
  toast: true,
  position: "bottom",
  showConfirmButton: false,
  timer: 3000,
});
